<template>
	<v-card class="mx-auto" :loading="loadingParent" rounded="xl">
		<v-card-title>
			<span>{{ $t('applications.applications') }}</span>
			<v-spacer />
			<div class="mr-3" style="max-width: 150px">
				<v-select
					v-model="showOption"
					:label="$t('search.show')"
					single-line
					:items="showOptions"
					item-value="key"
					item-text="name"
					return-object
					hide-details
				/>
			</div>
			<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search.search')" single-line hide-details />
		</v-card-title>

		<v-data-table
			:headers="headers"
			:items="filteredApplications"
			:items-per-page="25"
			:loading="loadingApplications"
			:locale="$i18n.locale"
			disable-sort
			class="elevation-1"
			v-if="applications.length && offer.applications.visible"
		>
			<template #item="{item, isMobile}">
				<template v-if="isMobile">
					<OfferApplicationsUserItemMobile :loading-parent="loadingOffer" :item="item" />
				</template>
				<template v-else>
					<OfferApplicationsUserItemDesktop :loading-parent="loadingOffer" :item="item" />
				</template>
			</template>
		</v-data-table>

		<template v-if="!offer.applications.visible">
			<p class="ma-5">{{ $t('applications.applicationsVisibilityHelp', { datetime: humanTime(offer.applications.timestampVisible) }) }}</p>
		</template>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	name: 'OfferApplications',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loadingApplications: false,
			search: '',
			showOption: { key: 'all', name: this.$t('applications.all') },
			showOptions: [
				{ key: 'all', name: this.$t('applications.all') },
				{ key: 'normal', name: this.$t('applications.normal') },
				{ key: 'prioritized', name: this.$t('applications.prioritized') },
				{ key: 'discarded', name: this.$t('applications.discarded') }
			],
			headers: [
				{ text: this.$t('auth.name'), value: 'user', align: 'center' },
				{ text: this.$t('settings.documents'), value: 'documents', align: 'center' },
				{ text: this.$t('settings.action'), value: 'action', align: 'center' }
			]
		}
	},
	components: {
		OfferApplicationsUserItemDesktop: () => import('@/components/offers/OfferApplicationsUserItemDesktop.vue'),
		OfferApplicationsUserItemMobile: () => import('@/components/offers/OfferApplicationsUserItemMobile.vue')
	},
	computed: {
		filteredApplications() {
			return this.applications.filter(
				(application) =>
					removeAccents(application.user.fullname)
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase()) && this.statusShown(application)
			)
		},
		...mapGetters({
			offer: 'offers/offer',
			applications: 'applications/applications'
		})
	},
	created() {
		this.loadingApplications = true
		this.fetchApplications(this.$route.params.id).then(() => {
			this.loadingApplications = false
		})
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
		},
		statusShown(application) {
			if (this.showOption.key == 'all') {
				return true
			} else if (this.showOption.key == 'normal') {
				return application.status.normal
			} else if (this.showOption.key == 'prioritized') {
				return application.status.prioritized
			} else if (this.showOption.key == 'discarded') {
				return application.status.discarded
			}
		},
		...mapActions('applications', ['fetchApplications'])
	}
}
</script>
